import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import "./Admin.css";
import PrivateRoutes from "./Routes/PrivateRoutes";
import Chats from "./components/USER/Message/Chats";
import PrivacyPolicy from "./components/USER/Modals/PrivacyPolicy";
import AboutPage from "./pages/USER/AboutPage";
import ChangePassword from "./pages/USER/AuthPages/ChangePassword";
import ForgotPassword from "./pages/USER/AuthPages/ForgotPassword";
import Login from "./pages/USER/AuthPages/Login";
import OtpVerification from "./pages/USER/AuthPages/OtpVerification";
import Register from "./pages/USER/AuthPages/Register";
import ContactPage from "./pages/USER/ContactPage";
import ErrorPage from "./pages/USER/ErrorPage";
import AddEventPage from "./pages/USER/EventPages/AddEventPage";
import ManageEventsPage from "./pages/USER/EventPages/ManageEventsPage";
import Home from "./pages/USER/Home";
import AddListingPage from "./pages/USER/ListingPages/AddListingPage";
import ListingDetailPage from "./pages/USER/ListingPages/ListingDetailPage";
import ListingListPage from "./pages/USER/ListingPages/ListingListPage";
import MyAdsPage from "./pages/USER/ListingPages/MyAdsPage";
import CopyRightPage from "./pages/USER/PoliciesPages/CopyRightPage";
import PolicyPage from "./pages/USER/PoliciesPages/PolicyPage";
import TermsPage from "./pages/USER/PoliciesPages/TermsPage";
import ProfilePage from "./pages/USER/ProfilePage";
import * as loginLocationActions from "./redux/LoginLocation/actions";
import TaskDetails from "./shared/Navbar/NewHeader/TaskStepper/taskDetails";
import ViewTaskPage from "./pages/USER/TaskPages/ViewTaskPage";
import Blogs from "./pages/USER/Blogs";
import FullBlog from "./pages/USER/Blogs/FullBlog";
import ImprintPage from "./pages/USER/ImprintPage";

const App = () => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const dispatch = useDispatch();
  const location = useLocation();

  let policyExecuted = localStorage.getItem("policy");

  const [previousPath, setPreviousPath] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const jwtToken = useSelector(
    (state) => state?.loginReducer?.user?.data?.jwtToken
  );
  const decodeJWT = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  };

  const isTokenExpired = (token) => {
    const decodedToken = decodeJWT(token);
    if (!decodedToken) return true;

    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

  useEffect(() => {
    if (jwtToken) {
      if (isTokenExpired(jwtToken)) {
        navigate("/");
      }
    }
  }, [jwtToken]);

  useEffect(() => {
    if (!isLoggedIn && location.pathname !== "/") {
      setPreviousPath(location.pathname);
    }
  }, [location, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && ["/chat"].includes(previousPath)) {
      dispatch(loginLocationActions.updatePreviousRoute(previousPath));
      dispatch(loginLocationActions.updateLoginPopup(true));
    } else {
      dispatch(loginLocationActions.updateLoginPopup(false));
    }
  }, [previousPath, isLoggedIn, dispatch]);

  useEffect(() => {
    const shouldShowLoginPopup =
      !isLoggedIn &&
      ["/ads/category", "/add-task"].some((path) =>
        previousPath.startsWith(path)
      );

    if (shouldShowLoginPopup) {
      dispatch(loginLocationActions.updatePreviousRoute(previousPath));
    }
  }, [dispatch, isLoggedIn, previousPath]);

  useEffect(() => {
    if (policyExecuted === "true") {
      setOpen(false);
    } else if (
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/verify-otp"
    ) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [location, policyExecuted]);

  return (
    <>
      <Routes>
        <Route path="/error" element={<ErrorPage />} />

        <Route path="/login" element={<PrivateRoutes Component={Login} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<OtpVerification />} />

        <Route path="/" element={<Home />} />

        <Route path="/ads/category/:id" element={<ListingListPage />}></Route>
        <Route path="/ads/:id" element={<ListingDetailPage />}></Route>

        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/imprint" element={<ImprintPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PolicyPage />} />
        <Route path="/copyright" element={<CopyRightPage />} />
        <Route path="/manage-events" element={<ManageEventsPage />} />
        <Route path="/add-events" element={<AddEventPage />} />
        <Route path="/add-task" element={<TaskDetails />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<FullBlog />} />

        {isLoggedIn && (
          <>
            <Route path="/my-profile" element={<ProfilePage />} />
            <Route path="/tasks/:id" element={<ViewTaskPage />} />
            <Route path="/edit-task/:id" element={<TaskDetails />} />
            <Route
              path="/add-listing"
              element={<PrivateRoutes Component={AddListingPage} />}
            >
              <Route
                path=":id"
                element={<PrivateRoutes Component={AddListingPage} />}
              />
            </Route>
            <Route
              path="/change-password"
              element={<PrivateRoutes Component={ChangePassword} />}
            />
            <Route path="/my-ads" element={<MyAdsPage />} />
            <Route path="/chat" element={<Chats />} />
            <Route path="/chat/:id" element={<Chats />} />
          </>
        )}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {open && <PrivacyPolicy handleClose={() => setOpen(false)} />}
    </>
  );
};

export default App;
